import * as React from "react";
import { graphql, HeadFC } from "gatsby";

import Layout from "../../../components/layouts/Default/Default";
import { ResponsiveBlock } from "../../../components/layouts/ResponsiveBlock/ResponsiveBlock";

import { CommonHead } from "../../../components/commons/Head/Head";

import { KoreanDivisionAbstruct } from "../../../components/elements/DivisionAbstruct/DivisionAbstruct";
import Hr from "../../../components/elements/Hr/Hr";
import {
  KLHeader,
  KLBox,
  KLCardHeader,
  KLCard,
  KLNumberHeader,
  KLNumbers,
  KLButtons,
  KLSection1,
  KLSection2,
  KLSection3,
} from "../../../components/elements/KoreanLeaf/KoreanLeaf";
import LeftBar from "../../../components/elements/LeftBar/LeftBar";
import SnsShare from "../../../components/elements/SnsShare/SnsShare";
import Vimeo from "../../../components/elements/Vimeo/Vimeo";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
  }
`;

const IndexPage = ({ data }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  return (
    <Layout title={title} pageType="TopPage">
      <>
        {/* メイン動画 */}
        <Vimeo
          vimeoId="944314699"
          privacyHash="62e4012259"
          autoPlay
          controls
          muted
        />
        {/* 韓国語用リーフヘッダー */}
        <KLHeader
          bgColorClass="tw-bg-[#EFB61C]"
          koreanTitle="느끼다"
          japaneseTitle="感じる"
          anchorList={[
            { id: "bunka", title: "文化" },
            { id: "entame", title: "エンタメ" },
            { id: "shinookubo", title: "新大久保" },
          ]}
          imageSrc="/images/korean/kv‐feel.jpg"
        />
        <KLBox sentence="学内での文化体験だけでなく、エンタメを通して韓国を感じる。新大久保への立地も自慢の一つ。" />
        <Hr />
        <LeftBar>
          <ResponsiveBlock bgColor="">
            <KLCardHeader title="文化" id="bunka" />
            <KLCard
              bgColorClass="tw-bg-[#FBF0D1]"
              imageSrc="/images/korean/2-1-feel.JPG"
              title="伝統文化から最新の韓国文化まで丸ごと体験"
              lines={[
                "伝統衣装や遊びの体験を通して伝統文化を理解する",
                "韓国文化を学び、韓国人の学生との交流を通し、両国の文化を比較する",
                "日本文化にも目を向け、発信カ・資料作成能カを高める",
              ]}
            />
            <KLCardHeader title="エンタメ" id="entame" />
            <KLCard
              bgColorClass="tw-bg-[#FBF0D1]"
              imageSrc="/images/korean/2-2-feel.JPG"
              title="エンタメだけではなく、プロからKコスメや美容を学び体験"
              lines={[
                "K-POPやドラマなど、自分の「好き」から韓国語や文化を学ぶ",
                "韓国のヘアメイク、ダンス、ファッションなどをプロから学び、体験する",
                "語学からエンタメまで幅広い分野を通して韓国を理解できる",
              ]}
            />
            <KLCardHeader title="新大久保" id="shinookubo" />
            <KLCard
              bgColorClass="tw-bg-[#FBF0D1]"
              imageSrc="/images/korean/2-3-feel.JPG"
              title="日本最大のコリアタウンで、疑似韓国を体験"
              lines={[
                "新大久保まで電車で2分、徒歩15分の立地",
                "学んだ韓国語を実践できる場所が近い",
                "韓国語を使って韓国人と一緒にアルバイトもできる",
              ]}
            />
          </ResponsiveBlock>
        </LeftBar>
        <Hr />
        <ResponsiveBlock bgColor="">
          <KLNumberHeader title="数字で見る" id="suujidemiru" />
          <KLNumbers
            items={[
              {
                title: "イベント",
                titleSmaller: "",
                subtitle: "",
                unitFront: "年間",
                unitSide: "",
                unitSideSmaller: false,
                threeDigits: false,
                number: "10",
                unitBack: "回以上",
                description:
                  "日本の文化、韓国の伝統文化やエンタメ文化を体験できるイベントがたくさんあります。",
              },
              {
                title: "新大久保",
                titleSmaller: "まで",
                subtitle: "",
                unitFront: "",
                unitSide: "電車で",
                unitSideSmaller: true,
                threeDigits: false,
                number: "2",
                unitBack: "分",
                description:
                  "休み時間や放課後には新大久保で学んだ韓国語を実践!アルバイトをしている在校生もいます。",
              },
            ]}
            marginTopHalf
          />
        </ResponsiveBlock>
        <Hr />
        <KLButtons
          buttons={[
            { name: "出会う", url: "/korean/meet/" },
            { name: "学ぶ", url: "/korean/learn/" },
            { name: "叶う", url: "/korean/realize/" },
          ]}
        />
        <Hr />
        <SnsShare
          path="/korean"
          title={title}
          buttonName="戻る"
          buttonUrl="/korean"
          spaceBottom
        />
        <Hr />
        <KoreanDivisionAbstruct spaceBottom />
      </>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead
    ogTitle={`感じる - 韓国語＋英語専攻 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    ogDescription="学内での文化体験だけでなく、エンタメを通して韓国を感じる。新大久保への立地も自慢の一つ"
    ogImageFilepath="/images/korean/kv‐feel.jpg"
  >
    <title>
      感じる - 韓国語＋英語専攻 - {data.allSettingsJson?.nodes[0].siteTitle}
    </title>
  </CommonHead>
);
